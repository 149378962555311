@use '@/scss/underscore' as _;

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: _.unit(2);
  gap: _.unit(4);

  &.withSubmitActionBar {
    padding-bottom: 0;
  }

  .fields {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: _.unit(4);
  }
}
