@use '@/scss/underscore' as _;

.dropdownIcon {
  color: var(--color-text-link);
}

.dropdown {
  min-width: unset;
}

.icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  color: transparent;

  &.selected {
    color: var(--color-primary-40);
  }
}

.title {
  display: flex;
  align-items: center;
  margin-left: _.unit(1);

  .optionText {
    font: var(--font-body-2);
    margin-left: _.unit(3);
  }

  &.selected {
    color: var(--color-primary-40);
  }
}
