@use '@/scss/underscore' as _;

.title {
  font: var(--font-title-3);
}

.list {
  padding-left: _.unit(6);

  li {
    word-break: break-word;
  }
}

.red {
  background-color: rgba(221, 55, 48, 30%);
}

.green {
  background-color: rgb(104, 190, 108, 40%);
}
