@use '@/scss/underscore' as _;

.container {
  display: flex;
  align-items: center;
  border: 1px solid var(--color-divider);
  border-radius: _.unit(2);
  padding: _.unit(4);
}

.description {
  font: var(--font-body-2);
  margin-right: _.unit(3);
}

.result {
  margin-top: _.unit(3);
}
