@use '@/scss/underscore' as _;

.planInfo {
  margin-bottom: _.unit(6);

  .name {
    font: var(--font-title-1);
  }

  .description {
    margin-top: _.unit(1);
    font: var(--font-body-2);
    color: var(--color-text-secondary);
  }
}

.notification {
  margin-top: _.unit(6);
}
