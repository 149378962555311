@use '@/scss/underscore' as _;

.container {
  display: inline-block;
  border-radius: 6px;
  color: var(--color-text);
  font: var(--font-body-2);
  font-family: 'Roboto Mono', monospace;
  cursor: default;

  &.contained {
    padding: _.unit(1) _.unit(2);
    background: var(--color-layer-2);
  }

  &.border {
    padding: _.unit(1) _.unit(3);
    background: var(--color-layer-2);
    border: 1px solid var(--color-border);
  }

  &.fullWidth {
    width: 100%;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: text;

    .content {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;

      &.wrapContent {
        text-overflow: unset;
        word-break: break-all;
      }
    }

    .copyToolTipAnchor {
      margin-left: _.unit(2);
    }
  }

  &.default {
    .row {
      .copyToolTipAnchor {
        margin-left: _.unit(3);
      }
    }
  }

  &.small {
    .row {
      .copyToolTipAnchor {
        margin-left: _.unit(1);
      }

      .iconButton {
        height: 20px;
        width: 20px;

        .icon {
          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}
