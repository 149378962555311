const guide = {
    start_building: 'Начать строить',
    get_started: 'Начать',
    categories: {
        featured: 'Популярные и для вас',
        Traditional: 'Традиционное веб-приложение',
        SPA: 'Одностраничное приложение',
        Native: 'Нативное',
        MachineToMachine: 'Машина к машине',
        /** UNTRANSLATED */
        Protected: 'Non-SDK Integration',
        /** UNTRANSLATED */
        ThirdParty: 'Third-party app',
    },
    filter: {
        title: 'Фильтры фреймворков',
        placeholder: 'Поиск фреймворка',
    },
    checkout_tutorial: 'Посмотреть руководство по {{name}}',
    do_not_need_tutorial: 'Если вам не нужно руководство, вы можете продолжить без руководства по фреймворку',
    finish_and_done: 'Завершить и закончить',
    cannot_find_guide: 'Не удается найти ваше руководство?',
    describe_guide_looking_for: 'Опишите руководство, которое вы ищете',
    request_guide_successfully: 'Ваш запрос был успешно отправлен. Спасибо!',
    app: {
        select_framework_or_tutorial: 'Выберите фреймворк или руководство',
        guide_modal_title: 'Начните с SDK и руководств',
        modal_subtitle: 'Ускорьте процесс разработки приложения с нашими готовыми SDK и руководствами.',
        select_a_framework: 'Выберите фреймворк',
        continue_without_framework: 'Создайте приложение без фреймворка',
        describe_guide_looking_for_placeholder: 'Например, я хочу интегрировать Logto в мое Angular приложение.',
    },
    api: {
        modal_title: 'Начните с руководств',
        modal_subtitle: 'Ускорьте процесс разработки приложения с нашими готовыми руководствами.',
        select_a_tutorial: 'Выберите руководство',
        continue_without_tutorial: 'Продолжить без руководства',
        describe_guide_looking_for_placeholder: 'Например, я хочу защитить мое API с помощью deno.',
    },
};
export default Object.freeze(guide);
