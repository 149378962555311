@use '@/scss/underscore' as _;

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: _.unit(4);

  label {
    color: var(--color-text-secondary);
    font: var(--font-section-head-1);
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  .card {
    display: flex;
    align-items: center;
    padding: _.unit(6) _.unit(8);
    gap: _.unit(6);
    background-color: var(--color-layer-1);
    border-radius: 12px;

    &.hasBorder {
      border: 1px solid var(--color-divider);
    }

    .logo {
      width: 48px;
      height: 48px;
    }

    .columnWrapper {
      flex: 1;
      display: flex;
      flex-direction: column;

      .titleRowWrapper {
        display: flex;
        align-items: center;
        gap: _.unit(3);
      }

      .title {
        font: var(--font-title-2);
        color: var(--color-text);
      }

      .label {
        font: var(--font-label-2);
        color: var(--color-text);
      }

      .description {
        font: var(--font-body-2);
        color: var(--color-text-secondary);
      }
    }
  }
}
