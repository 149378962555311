@use '@/scss/underscore' as _;

.roleTypes {
  display: flex;
  gap: _.unit(6);
}

.roleTypeSelectionSwitch {
  margin-top: _.unit(2);
}

.trailingIcon {
  width: 16px;
  height: 16px;

  > svg {
    width: 100%;
    height: 100%;
  }
}

.proTag {
  margin: 0;
  display: flex;
  align-items: center;
}
