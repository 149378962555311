@use '@/scss/underscore' as _;

.container {
  display: flex;
  flex-direction: column;
}

.uploader {
  display: flex;
  gap: _.unit(2);

  .logoUploader {
    flex: 2 0;
  }

  .faviconUploader {
    flex: 1;
  }
}

.description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: _.unit(1);
}

.error {
  color: var(--color-error);
}
