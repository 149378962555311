const tab_sections = {
    overview: 'Przegląd',
    resources: 'Zasoby',
    users: 'Użytkownicy',
    access_control: 'Kontrola dostępu',
    help_and_support: 'Pomoc i wsparcie',
    tenant: 'Najemca',
    automation: 'Automatyzacja',
};
export default Object.freeze(tab_sections);
