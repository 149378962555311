@use '@/scss/underscore' as _;

.minLength > div[class*='container'] {
  // From Figma design
  max-width: 156px;
}

.characterTypes {
  display: flex;
  gap: _.unit(6);
}

.textarea {
  margin-left: _.unit(7);
  margin-top: _.unit(2);
}

.passwordOption {
  // Every password option should be put in a `<FormField>` component, a margin
  // is added to the component to separate it from the previous one or the title.
  margin-top: _.unit(3);
  font: var(--font-body-2);

  > div[role='checkbox'] {
    align-items: flex-start;
  }

  .label {
    white-space: normal;

    .title {
      color: var(--color-text);
    }

    .description {
      color: var(--color-text-secondary);
      margin-top: _.unit(0.5);
    }
  }
}
