@use '@/scss/underscore' as _;

.page {
  height: 100%;
  padding-bottom: _.unit(6);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .metadata {
    display: flex;
    align-items: center;
    gap: _.unit(6);
  }

  .row {
    display: flex;
    align-items: center;
    gap: _.unit(1);
  }

  .name {
    font: var(--font-title-1);
    color: var(--color-text);
  }

  .label {
    font: var(--font-label-2);
    color: var(--color-text-secondary);
  }
}
