@use '@/scss/underscore' as _;

.container {
  border-radius: 12px;
  border: 1px solid var(--color-divider);
  background: var(--color-layer-1);
  padding: _.unit(5) _.unit(6);

  > div:not(:first-child) {
    margin-top: _.unit(2);
  }
}

.usage {
  font: var(--font-title-2);
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
}

.planCycle {
  font: var(--font-body-2);
}

.usageBar {
  border-radius: 4px;
  background-color: var(--color-layer-2);
  height: 18px;

  .usageBarInner {
    border-radius: 4px;
    background-color: var(--color-primary-80);
    height: 18px;

    &.overuse {
      background-color: var(--color-error-40);
    }
  }
}
