const tab_sections = {
    overview: 'Panoramica',
    resources: 'Risorse',
    users: 'Utenti',
    access_control: 'Controllo Accessi',
    help_and_support: 'Aiuto e Supporto',
    tenant: 'Locatario',
    automation: 'Automazione',
};
export default Object.freeze(tab_sections);
