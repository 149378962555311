const get_started = {
    page_title: '開始上手',
    title: '成功開發身份方案，我們先來探索一番',
    subtitle: '一些你可以做的事情，以快速獲取 Logto 的價值',
    develop: {
        title: '開發：花 5 分鐘將你的應用集成進來',
        /** UNTRANSLATED */
        title_cloud: 'Develop: Securely integrate your app in minutes',
        /** UNTRANSLATED */
        subtitle_cloud: 'Or take 5 minutes to integrate your app with our pre-built SDKs and tutorials.',
    },
    customize: {
        title: '定制：提供出色的登錄體驗',
        preview: {
            title: '查看剛剛定制的登錄體驗的實時預覽',
            subtitle: '現在嘗試 Logto 登錄體驗，看看它如何運作',
        },
        connector: {
            title: '添加更多連接器以支持更多社交登錄方式',
            subtitle: '試一試無密碼登錄，為你的客戶提供安全、無摩擦的體驗',
        },
        continue_customizing: '繼續定制',
        try_now: '立即試用',
        add_more: '添加更多',
    },
    secure: {
        title: '安全：保護你的資源',
    },
    manage: {
        title: '管理：為你的產品和用戶定義存取控制',
        rbac: {
            title: '為你的資源添加基於角色的存取控制',
            subtitle: '通過可擴展的角色授權，控制你的資源，滿足不同的使用場景',
        },
        create_roles: '創建角色',
    },
    view_all: '查看全部 →',
};
export default Object.freeze(get_started);
