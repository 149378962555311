@use '@/scss/underscore' as _;

.container {
  border-radius: 6px;
  border: 1px solid var(--color-border);
  outline: 3px solid transparent;
  padding: _.unit(2) _.unit(3);

  &:focus-within {
    border-color: var(--color-primary);
    outline-color: var(--color-focused-variant);
  }

  &.error {
    border-color: var(--color-error);

    &:focus-within {
      outline-color: var(--color-danger-focused);
    }
  }

  textarea {
    width: 100%;
    height: 100%;
    color: var(--color-text);
    font: var(--font-body-2);
    background: transparent;
    border: none;
    outline: none;
    resize: none;
    padding: 0;

    // Leave space for scrollbar by setting `box-sizing` and `padding-right`
    box-sizing: content-box;
    padding-right: 10px;

    &::placeholder {
      color: var(--color-placeholder);
    }
  }
}
