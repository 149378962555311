@use '@/scss/underscore' as _;

.modalLayoutWrapper {
  position: relative;
}

.headerIcon {
  width: 48px;
  height: 48px;
}

.content {
  padding: _.unit(6);
  background-color: var(--color-layer-light);
  border-radius: 12px;

  .title {
    font: var(--font-title-3);
  }

  .hint {
    font: var(--font-body-2);
    margin-top: _.unit(3);

    > div:not(:last-child) {
      margin-bottom: _.unit(4);
    }

    .strong {
      font: var(--font-label-2);
    }
  }
}

.fireworks {
  position: absolute;
  top: 0;
  pointer-events: none;

  .fireworksImage {
    transform: translateX(-10.5%) translateY(-18%);
  }
}
