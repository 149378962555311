@use '@/scss/underscore' as _;

.icon {
  width: 20px;
  height: 20px;
  border-radius: 6px;
}

.title {
  flex: 1 1 0;
  font: var(--font-body-2);
  @include _.text-ellipsis;
  margin-left: _.unit(2);
  max-width: fit-content;
}

.suspended {
  margin-left: _.unit(1);
}
