const guide = {
    start_building: 'Rozpocznij budowanie',
    get_started: 'Zacznij teraz',
    categories: {
        featured: 'Popularne i dla Ciebie',
        Traditional: 'Tradycyjna aplikacja internetowa',
        SPA: 'Aplikacja na jednej stronie',
        Native: 'Natywna',
        MachineToMachine: 'Maszyna-do-maszyny',
        /** UNTRANSLATED */
        Protected: 'Non-SDK Integration',
        /** UNTRANSLATED */
        ThirdParty: 'Third-party app',
    },
    filter: {
        title: 'Filtr Framework',
        placeholder: 'Szukaj framework',
    },
    checkout_tutorial: 'Sprawdź samouczek {{name}}',
    do_not_need_tutorial: 'Jeśli nie potrzebujesz samouczka, możesz kontynuować bez przewodnika frameworku',
    finish_and_done: 'Zakończ i zrobione',
    cannot_find_guide: 'Nie mogę znaleźć przewodnika?',
    describe_guide_looking_for: 'Opisz przewodnik, którego szukasz',
    request_guide_successfully: 'Twoje zgłoszenie zostało pomyślnie przesłane. Dziękujemy!',
    app: {
        select_framework_or_tutorial: 'Wybierz framework lub samouczek',
        guide_modal_title: 'Zacznij z SDK i przewodnikami',
        modal_subtitle: 'Przyspiesz swój proces rozwoju aplikacji za pomocą naszych prezentacji SDK i samouczków.',
        select_a_framework: 'Wybierz framework',
        continue_without_framework: 'Utwórz aplikację bez frameworku',
        describe_guide_looking_for_placeholder: 'Na przykład, chcę zintegrować Logto z moją aplikacją Angular.',
    },
    api: {
        modal_title: 'Zacznij od samouczków',
        modal_subtitle: 'Przyspiesz swój proces rozwoju aplikacji za pomocą naszych prezentacji samouczków.',
        select_a_tutorial: 'Wybierz samouczek',
        continue_without_tutorial: 'Kontynuuj bez samouczka',
        describe_guide_looking_for_placeholder: 'Na przykład, chcę chronić moje API przy użyciu deno.',
    },
};
export default Object.freeze(guide);
