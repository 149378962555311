@use '@/scss/underscore' as _;

.title {
  @include _.shimmering-animation;
  height: 24px;
  margin-bottom: _.unit(6);
}

.number {
  @include _.shimmering-animation;
  height: 32px;
}

.blocks {
  display: flex;
  align-items: center;
  margin-bottom: _.unit(4);
}

.block {
  flex: 1;

  &:not(:last-child) {
    margin-right: _.unit(4);
  }
}

.dau {
  width: 300px;
}

.curve {
  @include _.shimmering-animation;
  margin: _.unit(10) 0 _.unit(6);
  height: 168px;
}

.activeBlocks {
  display: flex;
  align-items: center;

  .block {
    border: 1px solid var(--color-divider);
    width: 360px;
    flex: unset;
  }
}
