@use '@/scss/underscore' as _;
@use '@/scss/dimensions' as dim;

.modalContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background: var(--color-base);
}

.stepContainer {
  flex: 1;
  overflow: hidden;
  width: 100%;
  padding: _.unit(6);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: _.unit(6);

  .card {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: dim.$guide-main-content-max-width;
    min-width: dim.$guide-content-min-width;
    padding: _.unit(12);
    gap: _.unit(6);

    .section {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: _.unit(3);
    }

    .image {
      width: 100%;
    }

    .icon {
      width: 48px;
      height: 48px;
      flex-shrink: 0;
    }

    .title {
      font: var(--font-title-1);
    }

    .subtitle {
      font: var(--font-title-2);
      color: var(--color-text-secondary);
    }

    .description {
      font: var(--font-body-2);
    }

    .fieldGroup {
      display: flex;
      flex-direction: column;
      gap: _.unit(3);
      width: 100%;

      > div {
        margin: 0;
      }
    }

    ul {
      margin: 0;
      padding-inline-start: 2ch;

      > li {
        padding-inline-start: _.unit(1);
      }
    }
  }
}
