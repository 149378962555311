const permissions = {
    search_placeholder: 'Szukaj według API lub nazwy uprawnienia',
    search_placeholder_without_api: 'Szukaj według nazwy uprawnienia',
    name_column: 'Uprawnienie',
    description_column: 'Opis',
    api_column: 'API',
    placeholder_title: 'Uprawnienie',
    placeholder_description: 'Uprawnienie odnosi się do autoryzacji dostępu do zasobu (nazywamy go zasobem API).',
};
export default Object.freeze(permissions);
