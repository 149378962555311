@use '@/scss/underscore' as _;

.item {
  display: flex;
  align-items: center;
  padding: _.unit(2) _.unit(3) _.unit(2) _.unit(4);
  font: var(--font-body-2);
  user-select: none;

  &:hover {
    background-color: var(--color-hover);
  }

  .info {
    flex: 1 1 0;
    display: flex;
    align-items: center;
    margin-right: _.unit(2);
    overflow: hidden;

    .name {
      @include _.text-ellipsis;
    }

    .count {
      flex-shrink: 0;
      margin-left: _.unit(2);
      color: var(--color-text-secondary);
    }
  }
}
