.message {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

.link {
  &:not(:disabled):hover {
    text-decoration: none;
  }
}
