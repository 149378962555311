@use '@/scss/underscore' as _;

.container {
  > div:not(:first-child) {
    margin-top: _.unit(4);
  }
}

.notes {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: _.unit(3);
}

.upsellNotification {
  margin-top: _.unit(4);
}
