const guide = {
    start_building: 'Commencer à construire',
    get_started: 'Démarrer',
    categories: {
        featured: 'Populaire et pour vous',
        Traditional: 'Application web traditionnelle',
        SPA: 'Application sur une seule page',
        Native: 'Natif',
        MachineToMachine: 'Machine-à-machine',
        /** UNTRANSLATED */
        Protected: 'Non-SDK Integration',
        /** UNTRANSLATED */
        ThirdParty: 'Third-party app',
    },
    filter: {
        title: 'Filtrer le framework',
        placeholder: 'Rechercher un framework',
    },
    checkout_tutorial: 'Voir le tutoriel {{name}}',
    do_not_need_tutorial: "Si vous n'avez pas besoin d'un tutoriel, vous pouvez continuer sans guide de framework",
    finish_and_done: 'Terminer et terminé',
    cannot_find_guide: 'Impossible de trouver votre guide ?',
    describe_guide_looking_for: 'Décrivez le guide que vous recherchez',
    request_guide_successfully: 'Votre demande a été soumise avec succès. Merci !',
    app: {
        select_framework_or_tutorial: 'Sélectionnez un framework ou un tutoriel',
        guide_modal_title: 'Commencez avec le SDK et les guides',
        modal_subtitle: 'Démarrez le processus de développement de votre application avec notre SDK et nos tutoriels pré-construits.',
        select_a_framework: 'Sélectionnez un framework',
        continue_without_framework: 'Créez une application sans framework',
        describe_guide_looking_for_placeholder: 'Par exemple, je veux intégrer Logto dans mon application Angular.',
    },
    api: {
        modal_title: 'Commencez avec les tutoriels',
        modal_subtitle: 'Démarrez le processus de développement de votre application avec nos tutoriels pré-construits.',
        select_a_tutorial: 'Sélectionnez un tutoriel',
        continue_without_tutorial: 'Continuer sans tutoriel',
        describe_guide_looking_for_placeholder: 'Par exemple, je veux protéger mon API en utilisant deno.',
    },
};
export default Object.freeze(guide);
