@use '@/scss/underscore' as _;

.factorField {
  display: flex;
  flex-direction: column;
  gap: _.unit(2);
}

.backupCodeField {
  display: flex;
  flex-direction: column;
  gap: _.unit(2);
  padding-top: _.unit(2);

  .backupCodeDescription {
    font: var(--font-body-2);
    color: var(--color-text-secondary);
  }
}

.unlockMfaNotice {
  margin-top: _.unit(4);
}
