@use '@/scss/underscore' as _;

.uploader {
  border: 1px dashed var(--color-border);
  border-radius: 8px;
  padding: _.unit(3.25);

  > input {
    display: none;
  }

  .placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
      width: 20px;
      height: 20px;
      color: var(--color-text-secondary);
    }

    .uploadingIcon {
      width: 20px;
      height: 20px;
      color: var(--color-primary);
    }

    .actionDescription {
      margin-top: _.unit(1);
      font: var(--font-body-2); // With font height to be 20px.
      user-select: none;
    }
  }

  &:hover {
    cursor: pointer;
    border-color: var(--color-primary);

    .placeholder {
      .icon {
        color: var(--color-primary);
      }
    }
  }

  &.dragActive {
    cursor: copy;
    background-color: var(--color-hover-variant);
    border-color: var(--color-primary);

    .placeholder {
      .icon {
        color: var(--color-primary);
      }
    }
  }

  &.uploaderError {
    border-color: var(--color-error);
  }
}

