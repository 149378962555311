const tab_sections = {
    overview: 'Overview',
    resources: 'Resources',
    users: 'Users',
    access_control: 'Access Control',
    help_and_support: 'Help and Support',
    tenant: 'Tenant',
    automation: 'Automation',
};
export default Object.freeze(tab_sections);
