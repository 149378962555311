@use '@/scss/underscore' as _;

.groupItem {
  user-select: none;

  .title {
    display: flex;
    align-items: center;
    padding: _.unit(1.5) _.unit(4);

    .group {
      flex: 1;
      display: flex;
      align-items: center;
      cursor: pointer;
      overflow: hidden;

      .caret {
        margin-right: _.unit(2);
      }

      .name {
        font: var(--font-label-2);
        @include _.text-ellipsis;
      }

      .dataInfo {
        flex-shrink: 0;
        font: var(--font-body-2);
        color: var(--color-text-secondary);
        margin-left: _.unit(2);
      }
    }
  }

  .invisible {
    display: none;
  }
}

.dataList {
  padding-left: _.unit(10);
}
