function Next() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9509 9.40832L8.23425 4.69999C8.15679 4.62188 8.06462 4.55989 7.96307 4.51758C7.86152 4.47527 7.7526 4.45349 7.64259 4.45349C7.53258 4.45349 7.42366 4.47527 7.32211 4.51758C7.22056 4.55989 7.12839 4.62188 7.05092 4.69999C6.89571 4.85613 6.80859 5.06734 6.80859 5.28749C6.80859 5.50764 6.89571 5.71885 7.05092 5.87499L11.1759 10.0417L7.05092 14.1667C6.89571 14.3228 6.80859 14.534 6.80859 14.7542C6.80859 14.9743 6.89571 15.1855 7.05092 15.3417C7.1281 15.4204 7.22014 15.483 7.3217 15.526C7.42326 15.5689 7.53233 15.5912 7.64259 15.5917C7.75284 15.5912 7.86191 15.5689 7.96348 15.526C8.06504 15.483 8.15708 15.4204 8.23425 15.3417L12.9509 10.6333C13.0355 10.5553 13.103 10.4606 13.1492 10.3552C13.1954 10.2497 13.2192 10.1359 13.2192 10.0208C13.2192 9.90574 13.1954 9.7919 13.1492 9.68648C13.103 9.58107 13.0355 9.48636 12.9509 9.40832Z"
        fill="#747778"
      />
    </svg>
  );
}

export default Next;
