@use '@/scss/underscore' as _;

.container {
  padding: _.unit(6);
  border-radius: _.unit(2);
  background-color: var(--color-layer-light);

  > div {
    display: flex;
    flex-direction: column;
    gap: _.unit(1);

    .title {
      font: var(--font-title-3);
    }

    .content {
      max-width: 100%;
      font: var(--font-body-2);
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }

  > :not(:last-child) {
    margin-bottom: _.unit(6);
  }
}
