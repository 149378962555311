const demo_app = {
    title: 'Live Preview에 성공적으로 로그인했습니다!',
    subtitle: '여기 로그인 정보가 있어요:',
    username: '사용자 이름: ',
    user_id: '사용자 ID: ',
    sign_out: 'Live Preview에서 로그아웃',
    continue_explore: '또는 계속해서 살펴보기',
    customize_sign_in_experience: '로그인 경험 커스터마이징',
    enable_passwordless: '비밀번호 없는 로그인 활성화',
    add_social_connector: '소셜 연동 추가',
};
export default Object.freeze(demo_app);
