const get_started = {
    page_title: '開始上手',
    title: '成功開發身份方案，我們先來探索一番',
    subtitle: '一些快速獲取 Logto 價值的事情',
    develop: {
        title: '開發：花 5 分鐘集成您的應用程式',
        /** UNTRANSLATED */
        title_cloud: 'Develop: Securely integrate your app in minutes',
        /** UNTRANSLATED */
        subtitle_cloud: 'Or take 5 minutes to integrate your app with our pre-built SDKs and tutorials.',
    },
    customize: {
        title: '自訂：提供出色的登錄體驗',
        preview: {
            title: '查看您剛自訂的登錄體驗的實時預覽',
            subtitle: '立即嘗試 Logto 登錄體驗，了解其工作原理',
        },
        connector: {
            title: '添加更多連接器以支持更多社交登錄方式',
            subtitle: '嘗試無密碼登錄，為您的客戶啟用安全、無摩擦的體驗',
        },
        continue_customizing: '繼續自訂',
        try_now: '立即嘗試',
        add_more: '添加更多',
    },
    secure: {
        title: '安全：保護您的資源',
    },
    manage: {
        title: '管理：為您的產品和用戶定義訪問控制',
        rbac: {
            title: '添加基於角色的訪問控制以保護您的資源',
            subtitle: '通過可擴展的角色授權，來控制不同用例的資源',
        },
        create_roles: '創建角色',
    },
    view_all: '查看全部 →',
};
export default Object.freeze(get_started);
