const demo_app = {
    title: "You've successfully signed in the live preview!",
    subtitle: 'Aqui estão suas informações de login:',
    username: 'Nome de usuário: ',
    user_id: 'ID do usuário: ',
    sign_out: 'Sair da Visualização ao Vivo',
    continue_explore: 'Ou continuar a navegação',
    customize_sign_in_experience: 'Personalize a experiência de login',
    enable_passwordless: 'Ativar acesso livre',
    add_social_connector: 'Adicionar conector social',
};
export default Object.freeze(demo_app);
