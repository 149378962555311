@use '@/scss/underscore' as _;

.section {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: _.unit(3);
}

.addAnother {
  margin-top: _.unit(3);
}

.empty {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

.title {
  font: var(--font-label-2);
}

.spinner {
  margin: _.unit(2) 0;
}
