@use '@/scss/underscore' as _;

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: _.unit(3);
}

.title {
  font: var(--font-label-2);
}

.item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: _.unit(2);

  .fieldWrapper {
    flex: 1;
    background: var(--color-layer-light);
    padding: _.unit(4);
    border-radius: 12px;
  }
}

.skeleton {
  width: 100%;
  padding: _.unit(1) 0;

  .group {
    display: flex;
    flex-direction: column;
    gap: _.unit(3);
    width: 100%;

    + .group {
      margin-top: _.unit(6);
    }
  }

  .title {
    width: 200px;
    height: 20px;
    @include _.shimmering-animation;
  }

  .field {
    width: 100%;
    height: 36px;
    @include _.shimmering-animation;
  }
}
