const permissions = {
    search_placeholder: 'Nach API oder Berechtigungsname suchen',
    search_placeholder_without_api: 'Nach Berechtigungsname suchen',
    name_column: 'Berechtigung',
    description_column: 'Beschreibung',
    api_column: 'API',
    placeholder_title: 'Berechtigung',
    placeholder_description: 'Berechtigung bezieht sich auf die Autorisierung zum Zugriff auf eine Ressource (wir nennen sie API-Ressource).',
};
export default Object.freeze(permissions);
