@use '@/scss/underscore' as _;

.container {
  padding: _.unit(3);
  border-radius: 16px;
  background-color: var(--color-layer-1);
  margin-bottom: _.unit(3);

  table {
    border: none;
    border-spacing: 0;
    table-layout: fixed;
    width: 100%;

    thead {
      border-radius: 6px;
      padding: 0 _.unit(3);

      tr th {
        font: var(--font-title-2);
        padding: _.unit(3);
      }
    }

    tbody {
      tr td {
        font: var(--font-body-2);
        text-align: center;
        padding: _.unit(3);
      }

      .groupLabel {
        font: var(--font-title-2);
        text-align: left;
        background-color: var(--color-layer-light);
        border-radius: 6px;
      }

      .quotaKeyColumn {
        padding: _.unit(4) _.unit(6);
        text-align: left;
      }
    }
  }
}
