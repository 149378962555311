const tab_sections = {
    overview: '概要',
    resources: 'リソース',
    users: 'ユーザー',
    access_control: 'アクセス制御',
    help_and_support: 'ヘルプとサポート',
    tenant: 'テナント',
    automation: 'オートメーション',
};
export default Object.freeze(tab_sections);
