@use '@/scss/underscore' as _;

.suspendedPage {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .image {
    flex-shrink: 0;
  }

  .title {
    font: var(--font-title-1);
  }

  .description {
    font: var(--font-body-2);
    text-align: center;
    margin: _.unit(2);
    width: 470px;
  }

  .linkButton {
    margin-top: _.unit(4);
    text-decoration: none;
  }
}
