@use '@/scss/underscore' as _;

.item {
  border: 1px solid var(--color-border);
  border-radius: 12px;
  min-height: 80px;
  padding: _.unit(5);
  font: var(--font-label-2);
  user-select: none;
  background-color: var(--color-layer-1);
  color: var(--color-text);
  display: flex;
  align-items: center;

  .icon {
    color: var(--color-text-secondary);
    margin-right: _.unit(4);
    vertical-align: middle;

    > svg {
      display: block;
    }
  }

  .content {
    .tag {
      font: var(--font-body-3);
      color: var(--color-text-secondary);
    }

    .trailingTag {
      margin-left: _.unit(1);
    }
  }

  &.disabled {
    border-color: var(--color-layer-2);
    background-color: var(--color-layer-2);

    &:hover {
      cursor: not-allowed;
    }
  }

  &:not(.disabled).selected {
    border-color: var(--color-primary);
    background-color: var(--color-hover-variant);
    color: var(--color-primary);

    .icon {
      color: var(--color-primary);
    }
  }

  &:not(.disabled):hover {
    cursor: pointer;
    border-color: var(--color-primary);
    color: var(--color-primary);

    .icon {
      color: var(--color-primary);
    }
  }
}

