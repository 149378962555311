@use '@/scss/underscore' as _;
@use '@/scss/dimensions' as dim;

.container {
  width: 100%;
}

.wrapper {
  width: 100%;
  min-width: dim.$guide-content-min-width;
  max-width: dim.$guide-main-content-max-width + 2 * dim.$guide-content-padding;
  margin: 0 auto;
  position: relative;
  padding: dim.$guide-content-padding;
}

.groups {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: _.unit(8);
  position: relative;
  max-width: dim.$guide-main-content-max-width;

  > div {
    flex: unset;
  }
}

.guideGroup {
  flex: 1;
}

.emptyPlaceholder {
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 70%;
}
