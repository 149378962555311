const paywall = {
    applications: 'Достигнут лимит {{count, number}} приложения(й) для <planName/>. Обновите план, чтобы удовлетворить потребности вашей команды. При необходимости помощи, не стесняйтесь <a>связаться с нами</a>.',
    applications_other: 'Достигнут лимит {{count, number}} приложений для <planName/>. Обновите план, чтобы удовлетворить потребности вашей команды. При необходимости помощи, не стесняйтесь <a>связаться с нами</a>.',
    machine_to_machine_feature: 'Переключитесь на план <strong>Pro</strong>, чтобы получить дополнительные машинные приложения и воспользоваться всеми премиальными функциями. <a>Свяжитесь с нами</a>, если у вас есть вопросы.',
    machine_to_machine: 'Достигнут лимит {{count, number}} машинных приложений для <planName/>. Обновите план, чтобы удовлетворить потребности вашей команды. При необходимости помощи, не стесняйтесь <a>связаться с нами</a>.',
    machine_to_machine_other: 'Достигнут лимит {{count, number}} машинных приложений для <planName/>. Обновите план, чтобы удовлетворить потребности вашей команды. При необходимости помощи, не стесняйтесь <a>связаться с нами</a>.',
    resources: 'Достигнут лимит {{count, number}} ресурсов API в плане <planName/>. Повысьте план, чтобы удовлетворить потребности вашей команды. <a>Свяжитесь с нами</a> для получения помощи.',
    resources_other: 'Достигнут лимит {{count, number}} ресурсов API в плане <planName/>. Повысьте план, чтобы удовлетворить потребности вашей команды. <a>Свяжитесь с нами</a> для получения помощи.',
    scopes_per_resource: 'Достигнут лимит {{count, number}} разрешений на ресурс API в плане <planName/>. Повысьте план, чтобы увеличить количество разрешений. <a>Свяжитесь с нами</a> для получения помощи.',
    scopes_per_resource_other: 'Достигнут лимит {{count, number}} разрешений на ресурс API в плане <planName/>. Повысьте план, чтобы увеличить количество разрешений. <a>Свяжитесь с нами</a> для получения помощи.',
    custom_domain: 'Разблокируйте функцию настраиваемого домена, обновив план до <strong>Hobby</strong> или <strong>Pro</strong>. Не стесняйтесь <a>связаться с нами</a>, если вам нужна помощь.',
    social_connectors: 'Достигнут лимит {{count, number}} социальных коннекторов в плане <planName/>. Для удовлетворения потребностей вашей команды повысьте план, чтобы получить дополнительные социальные коннекторы и возможность создания собственных коннекторов с использованием протоколов OIDC, OAuth 2.0 и SAML. Если вам нужна помощь, не стесняйтесь <a>связаться с нами</a>.',
    social_connectors_other: 'Достигнут лимит {{count, number}} социальных коннекторов в плане <planName/>. Для удовлетворения потребностей вашей команды повысьте план, чтобы получить дополнительные социальные коннекторы и возможность создания собственных коннекторов с использованием протоколов OIDC, OAuth 2.0 и SAML. Если вам нужна помощь, не стесняйтесь <a>связаться с нами</a>.',
    standard_connectors_feature: 'Обновите план до <strong>Hobby</strong> или <strong>Pro</strong>, чтобы создавать собственные коннекторы с использованием протоколов OIDC, OAuth 2.0 и SAML, а также получить неограниченное количество социальных коннекторов и все премиальные функции. Не стесняйтесь <a>связаться с нами</a>, если вам нужна помощь.',
    standard_connectors: 'Достигнут лимит {{count, number}} социальных коннекторов в плане <planName/>. Для удовлетворения потребностей вашей команды повысьте план, чтобы получить дополнительные социальные коннекторы и возможность создания собственных коннекторов с использованием протоколов OIDC, OAuth 2.0 и SAML. Если вам нужна помощь, не стесняйтесь <a>связаться с нами</a>.',
    standard_connectors_other: 'Достигнут лимит {{count, number}} социальных коннекторов в плане <planName/>. Для удовлетворения потребностей вашей команды повысьте план, чтобы получить дополнительные социальные коннекторы и возможность создания собственных коннекторов с использованием протоколов OIDC, OAuth 2.0 и SAML. Если вам нужна помощь, не стесняйтесь <a>связаться с нами</a>.',
    standard_connectors_pro: 'Достигнут лимит {{count, number}} стандартных коннекторов в плане <planName/>. Для удовлетворения потребностей вашей команды повысьте план до Плана предприятия, чтобы получить дополнительные социальные коннекторы и возможность создания собственных коннекторов с использованием протоколов OIDC, OAuth 2.0 и SAML. Если вам нужна помощь, не стесняйтесь <a>связаться с нами</a>.',
    standard_connectors_pro_other: 'Достигнут лимит {{count, number}} стандартных коннекторов в плане <planName/>. Для удовлетворения потребностей вашей команды повысьте план до Плана предприятия, чтобы получить дополнительные социальные коннекторы и возможность создания собственных коннекторов с использованием протоколов OIDC, OAuth 2.0 и SAML. Если вам нужна помощь, не стесняйтесь <a>связаться с нами</a>.',
    roles: 'Достигнут лимит {{count, number}} ролей в плане <planName/>. Повысьте план, чтобы добавить дополнительные роли и разрешения. Если вам нужна помощь, не стесняйтесь <a>связаться с нами</a>.',
    roles_other: 'Достигнут лимит {{count, number}} ролей в плане <planName/>. Повысьте план, чтобы добавить дополнительные роли и разрешения. Если вам нужна помощь, не стесняйтесь <a>связаться с нами</a>.',
    machine_to_machine_roles: '{{count, number}} роль для машинного приложения в пределах <planName/> достигла лимита. Повысьте план, чтобы добавить дополнительные роли и разрешения. Не стесняйтесь <a>связаться с нами</a>, если вам нужна помощь.',
    machine_to_machine_roles_other: '{{count, number}} ролей для машинного приложения в пределах <planName/> достигли лимита. Повысьте план, чтобы добавить дополнительные роли и разрешения. Не стесняйтесь <a>связаться с нами</a>, если вам нужна помощь.',
    scopes_per_role: 'Достигнут лимит {{count, number}} разрешений на роль в плане <planName/>. Повысьте план, чтобы добавить дополнительные роли и разрешения. Если вам нужна помощь, не стесняйтесь <a>связаться с нами</a>.',
    scopes_per_role_other: 'Достигнут лимит {{count, number}} разрешений на роль в плане <planName/>. Повысьте план, чтобы добавить дополнительные роли и разрешения. Если вам нужна помощь, не стесняйтесь <a>связаться с нами</a>.',
    hooks: 'Достигнут лимит {{count, number}} вебхуков в плане <planName/>. Повысьте план, чтобы создать больше вебхуков. Если вам нужна помощь, не стесняйтесь <a>связаться с нами</a>.',
    hooks_other: 'Достигнут лимит {{count, number}} вебхуков в плане <planName/>. Повысьте план, чтобы создать больше вебхуков. Если вам нужна помощь, не стесняйтесь <a>связаться с нами</a>.',
    mfa: 'Разблокируйте MFA для повышения безопасности с помощью перехода на платный план. Не стесняйтесь <a>связаться с нами</a>, если вам нужна помощь.',
    organizations: 'Разблокируйте организации, перейдя на платный план. Не стесняйтесь <a>связаться с нами</a>, если вам нужна помощь.',
    /** UNTRANSLATED */
    third_party_apps: 'Unlock Logto as IdP for third-party apps by upgrading to a paid plan. For any assistance, feel free to <a>contact us</a>.',
};
export default Object.freeze(paywall);
