@use '@/scss/underscore' as _;

.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .positionInfo {
    font: var(--font-body-2);
    color: var(--color-text-secondary);
  }

  .pagination {
    display: flex;
    justify-content: right;
    margin: 0;
    height: 28px;
    padding-inline-start: _.unit(4);

    li {
      list-style: none;

      &:not(:first-child) {
        margin-left: _.unit(2);
      }

      .button {
        display: block;
        border-radius: 6px;
        min-width: 28px;
        padding: 0 6px;
        height: 28px;
        text-overflow: unset;
        border: 1px solid var(--color-border);

        > span {
          margin: 0 auto;
        }

        &.active {
          border-color: var(--color-text-link);
          color: var(--color-text-link);
        }
      }
    }
  }

  li.disabled {
    cursor: not-allowed;

    .button {
      background: var(--color-neutral-95);
    }
  }

  &.pico {
    .pagination {
      height: 20px;

      li {
        .button {
          border-radius: 4px;
          height: 20px;
          min-width: unset;
          border: unset;
          background: unset;
          padding: 0;
        }
      }
    }
  }
}
