@use '@/scss/underscore' as _;

.quotaValue {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: _.unit(1);

  &.leftAligned {
    justify-content: flex-start;
  }
}

.extraInfo {
  font: var(--font-body-3);
  color: var(--color-text-secondary);
}
