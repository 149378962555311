@use '@/scss/underscore' as _;

.item {
  display: flex;
  align-items: center;
  padding: _.unit(2) _.unit(3) _.unit(2) _.unit(4);
  user-select: none;

  .meta {
    flex: 1;
    display: flex;
    align-items: center;
    overflow: hidden;

    .icon {
      width: 20px;
      height: 20px;
      border-radius: 6px;
    }

    .title {
      flex: 1 1 0;
      font: var(--font-body-2);
      @include _.text-ellipsis;
      margin-left: _.unit(2);
      max-width: fit-content;
    }

    .suspended {
      margin: 0 _.unit(1);
    }
  }

  &:hover {
    background: var(--color-hover);
  }
}
