const permissions = {
    search_placeholder: "Rechercher par nom d'API ou de permission",
    search_placeholder_without_api: 'Rechercher par nom de permission',
    name_column: 'Permission',
    description_column: 'Description',
    api_column: 'API',
    placeholder_title: 'Permission',
    placeholder_description: "La permission fait référence à l'autorisation d'accéder à une ressource (nous l'appelons ressource d'API).",
};
export default Object.freeze(permissions);
