@use '@/scss/underscore' as _;

.container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;

  .title {
    font: var(--font-label-2);
    color: var(--color-text);
    margin-top: _.unit(2.5);
  }

  .subtitle,
  .description {
    margin-top: _.unit(3);
    flex: 2;
    font: var(--font-body-2);
  }

  .subtitle {
    color: var(--color-text);
  }

  .description {
    color: var(--color-text-secondary);
  }

  &.small {
    .subtitle,
    .description {
      margin-top: _.unit(1);
      flex: 2;
      font: var(--font-body-3);
    }
  }
}
