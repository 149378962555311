const cloud = {
    general: {
        onboarding: 'Вводный курс',
    },
    welcome: {
        page_title: 'Добро пожаловать',
        title: 'Добро пожаловать в облако Logto! Мы хотели бы узнать вас получше.',
        description: 'Сделайте свой опыт работы с Logto уникальным для вас, узнав вас получше. Ваша информация надежно защищена.',
        project_field: 'Я использую Logto для',
        project_options: {
            personal: 'Личного проекта',
            company: 'Компании',
        },
        company_name_field: 'Название компании',
        company_name_placeholder: 'Acme.co',
        stage_field: 'На каком этапе находится ваш продукт сейчас?',
        stage_options: {
            new_product: 'Начать новый проект и ищете быстрое, заранее созданное решение',
            existing_product: 'Миграция с текущей аутентификации (например, собственная разработка, Auth0, Cognito, Microsoft)',
            target_enterprise_ready: 'Я только что получил больших клиентов и теперь готовлю свой продукт к продаже предприятиям',
        },
        additional_features_field: 'Хотели бы вы сообщить нам что-то еще?',
        additional_features_options: {
            customize_ui_and_flow: 'Построить и управлять своим собственным пользовательским интерфейсом, а не просто использовать заранее созданное и настраиваемое решение Logto',
            compliance: 'SOC2 и GDPR - обязательные требования',
            export_user_data: 'Необходима возможность экспортировать данные пользователей из Logto',
            budget_control: 'У меня очень тщательный контроль над бюджетом',
            bring_own_auth: 'Имею собственные службы аутентификации и просто нужны некоторые возможности Logto',
            others: 'Ничего из вышеперечисленного',
        },
    },
    sie: {
        page_title: 'Настройка опыта входа',
        title: 'Давайте сначала легко настроим ваш опыт входа',
        inspire: {
            title: 'Создайте убедительные примеры',
            description: 'Чувствуете неуверенность в опыте входа? Просто нажмите «Вдохновить меня» и позвольте волшебству совершиться!',
            inspire_me: 'Вдохнови меня',
        },
        logo_field: 'Логотип приложения',
        color_field: 'Цвет бренда',
        identifier_field: 'Идентификатор',
        identifier_options: {
            email: 'Электронная почта',
            phone: 'Номер телефона',
            user_name: 'Имя пользователя',
        },
        authn_field: 'Аутентификация',
        authn_options: {
            password: 'Пароль',
            verification_code: 'Код подтверждения',
        },
        social_field: 'Вход через социальную сеть',
        finish_and_done: 'Готово',
        preview: {
            mobile_tab: 'Мобильный',
            web_tab: 'Веб',
        },
        connectors: {
            unlocked_later: 'Разблокируется позже',
            unlocked_later_tip: 'После того, как завершите процесс настройки и войдете в продукт, у вас появится доступ к большему количеству методов входа через социальные сети.',
            notice: 'Пожалуйста, не используйте демонстрационный коннектор для производственных целей. После тестирования удалите демонстрационный коннектор и настройте собственный коннектор со своими учетными данными.',
        },
    },
    socialCallback: {
        title: 'Вход выполнен успешно',
        description: 'Вы успешно вошли, используя свою учетную запись в социальной сети. Чтобы обеспечить безпроблемную интеграцию и доступ ко всем функциям Logto, рекомендуем настроить свой собственный социальный коннектор.',
    },
    tenant: {
        create_tenant: 'Создать арендатора',
    },
};
export default Object.freeze(cloud);
