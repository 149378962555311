.container {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: var(--color-hover);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  > img {
    width: 28px;
    height: 28px;
  }

  &.large {
    width: 60px;
    height: 60px;
    border-radius: 12px;

    > img {
      width: 42px;
      height: 42px;
    }
  }

  &.small {
    width: 20px;
    height: 20px;
    background-color: transparent;
    border-radius: unset;

    > img {
      width: 20px;
      height: 20px;
    }
  }
}

.logo {
  width: 40px;
  height: 40px;
  flex-shrink: 0;

  &.large {
    width: 60px;
    height: 60px;
  }

  &.small {
    width: 20px;
    height: 20px;
  }
}
