const tabs = {
    get_started: 'Erste Schritte',
    dashboard: 'Dashboard',
    applications: 'Anwendungen',
    api_resources: 'API Ressourcen',
    sign_in_experience: 'Anmeldeoberfläche',
    connectors: 'Connectoren',
    enterprise_sso: 'Unternehmens-SSO',
    webhooks: 'Webhooks',
    organizations: 'Organisationen',
    users: 'Benutzerverwaltung',
    audit_logs: 'Audit-Logs',
    roles: 'Rollen',
    docs: 'Dokumentation',
    tenant_settings: 'Einstellungen',
    mfa: 'Multi-Faktor-Authentifizierung',
};
export default Object.freeze(tabs);
