@use '@/scss/underscore' as _;

.rolesTable {
  flex: 1;
  margin-bottom: _.unit(6);
  color: var(--color-text);

  .filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .description {
    @include _.text-ellipsis;
  }
}
