@use '@/scss/underscore' as _;

.field {
  display: flex;
  align-items: center;

  .tipButton {
    margin-left: _.unit(0.5);
  }
}

.title {
  font: var(--font-label-2);
}

.content {
  font: var(--font-body-2);
}
