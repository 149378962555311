@use '@/scss/underscore' as _;

.container {
  width: 100%;
  font: var(--font-label-2);

  .title {
    margin-bottom: _.unit(1);
  }

  .actionButton {
    display: inline-flex;

    + .actionButton {
      margin-left: _.unit(4);
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
  }

  .icon {
    width: 32px;
    height: 32px;
    margin-right: _.unit(4);
  }

  table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid var(--color-neutral-variant-90);
    border-radius: 8px;

    td {
      font: var(--font-body-2);
      height: 64px;
      padding: 0 _.unit(6);
      border-bottom: 1px solid var(--color-neutral-variant-90);

      &:first-child {
        max-width: 248px;
        width: 25%;
        font: var(--font-label-2);
      }

      &:last-child {
        text-align: right;
        width: 25%;

        .wrapper {
          justify-content: flex-end;
        }
      }

      > span {
        @include _.text-ellipsis;
        display: inline-block;
        max-width: 320px;
      }
    }

    tr:last-child td {
      border-bottom: none;
    }
  }

  &:not(:first-child) {
    margin-top: _.unit(6);
  }
}
