@use '@/scss/underscore' as _;

.deleteConfirm {
  > :not(:first-child) {
    margin-top: _.unit(6);
  }

  .description {
    font: var(--font-body-2);
  }

  .highlight {
    color: var(--color-primary-50);
  }
}

.customEndpointNotes {
  margin-top: _.unit(6);
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

.fieldButton {
  margin-top: _.unit(2);
}

.trailingIcon {
  width: 16px;
  height: 16px;
}

.tabContainer {
  flex-direction: column;
  flex-grow: 1;

  &[data-active='true'] {
    display: flex;
  }
}
