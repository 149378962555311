const tab_sections = {
    overview: '살펴보기',
    resources: '자원',
    users: '사용자',
    access_control: '접근 제어',
    help_and_support: '고객센터',
    tenant: '세입자',
    automation: '자동화',
};
export default Object.freeze(tab_sections);
