@use '@/scss/underscore' as _;
@use '@/scss/dimensions' as dim;


.content {
  flex: 1;
  position: relative;

  section {
    margin: _.unit(6) 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    font: var(--font-title-2);
    color: var(--color-text-secondary);
    margin: _.unit(6) 0 _.unit(3);
  }

  p {
    font: var(--font-body-2);
    margin: _.unit(4) 0;
  }

  ul,
  ol {
    margin-block: _.unit(4);
    padding-inline-start: _.unit(4);
  }

  ul > li,
  ol > li {
    font: var(--font-body-2);
    margin-block: _.unit(2);
    padding-inline-start: _.unit(1);
  }

  table {
    border-spacing: 0;
    border: 1px solid var(--color-border);
    font: var(--font-body-2);

    tr {
      width: 100%;
    }

    td,
    th {
      padding: _.unit(2) _.unit(4);
    }

    thead {
      font: var(--font-title-3);
    }

    tbody td {
      border-top: 1px solid var(--color-border);
    }
  }

  code:not(pre > code) {
    background: var(--color-layer-2);
    font: var(--font-body-2);
    padding: _.unit(1);
    border-radius: 4px;
  }

  img {
    width: 90%;
    border-radius: _.unit(2);
  }
}
