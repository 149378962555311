const permissions = {
    search_placeholder: 'Procurar por nome de API ou permissão',
    search_placeholder_without_api: 'Procurar por nome de permissão',
    name_column: 'Permissão',
    description_column: 'Descrição',
    api_column: 'API',
    placeholder_title: 'Permissão',
    placeholder_description: 'Permissão refere-se à autorização para acessar um recurso (chamamos de recurso de API).',
};
export default Object.freeze(permissions);
