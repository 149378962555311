@use '@/scss/underscore' as _;

.sourceScopeItem {
  display: flex;
  align-items: center;
  padding: _.unit(1.5)  _.unit(4) _.unit(1.5) _.unit(11.5);

  .name {
    font: var(--font-body-2);
    padding: _.unit(1) _.unit(2);
    border-radius: 6px;
    background: var(--color-neutral-95);
    @include _.text-ellipsis;
    cursor: pointer;
  }

  .icon {
    color: var(--color-text-secondary);
  }
}
