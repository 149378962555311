@use '@/scss/underscore' as _;

.formFieldDescription {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin: _.unit(1) 0 _.unit(2);
}

.errorMessage {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: _.unit(4);
}
