@use '@/scss/underscore' as _;

.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .title {
    color: var(--color-text);
    display: flex;
    align-items: center;
    gap: _.unit(2);
  }

  .titleEllipsis {
    @include _.text-ellipsis;
  }

  .subtitle {
    margin-top: _.unit(1);
    color: var(--color-text-secondary);
  }

  .learnMore:not(:first-child) {
    margin-left: _.unit(1);
  }

  &.large {
    .title {
      font: var(--font-title-1);
    }

    .subtitle {
      font: var(--font-body-2);
    }
  }

  &.medium {
    .title {
      font: var(--font-title-2);
    }

    .subtitle {
      font: var(--font-body-2);
    }
  }

  &.small {
    .title {
      font: var(--font-title-2);
    }

    .subtitle {
      font: var(--font-body-3);
      margin-top: 0;
    }
  }
}
