@use '@/scss/underscore' as _;

.requestSdkButton {
  margin-right: _.unit(15);
}

@media screen and (max-width: 918px) {
  .requestSdkButton {
    margin-right: 0;
  }
}
