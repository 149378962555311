@use '@/scss/underscore' as _;

.suffix {
  width: _.unit(8);
  height: _.unit(8);
  display: none;

  &.visible {
    display: block;
  }
}

.hideTextContainerContent {
  input {
    -webkit-text-security: disc;
  }
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--color-border);
  outline: 3px solid transparent;
  transition-property: outline, border;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  padding: 0 _.unit(3);
  height: 36px;
  background-color: inherit;
  font: var(--font-body-2);

  &.withIcon {
    display: flex;
    align-items: center;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: _.unit(2);
    }
  }

  &:focus-within {
    border-color: var(--color-primary);
    outline-color: var(--color-focused-variant);

    .suffix {
      display: block;
    }
  }

  input {
    flex: 1;
    appearance: none;
    color: var(--color-text);
    font: var(--font-body-2);
    background: transparent;
    padding: 0;

    &::placeholder {
      color: var(--color-placeholder);
    }

    // Overwrite webkit auto-fill style
    &:-webkit-autofill {
      box-shadow: 0 0 0 30px var(--color-layer-1) inset;
      -webkit-text-fill-color: var(--color-text);
      caret-color: var(--color-text);
    }

    &[type='date'] {
      height: 20px;

      &::-webkit-calendar-picker-indicator {
        background-image: none;
        background-color: var(--color-text-secondary);
        mask-image: url('raw:../../assets/icons/calendar-outline.svg');
        mask-size: 20px 20px;
        width: 16px;
        height: 18px;
      }
    }

    &[type='number'] {
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  &.disabled {
    background: var(--color-inverse-on-surface);
    color: var(--color-text-secondary);
    border-color: var(--color-border);
  }

  &.readOnly {
    background: var(--color-layer-2);
    color: var(--color-text);
    border-color: var(--color-border);

    &:focus-within {
      border-color: var(--color-border);
      outline-color: transparent;
    }
  }

  &.error {
    border-color: var(--color-error);

    &:focus-within {
      outline-color: var(--color-danger-focused);
    }
  }
}

.errorMessage {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: _.unit(1);

  a {
    color: var(--color-error);
    text-decoration: underline;
  }
}
