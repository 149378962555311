@use '@/scss/underscore' as _;

.input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 _.unit(2) 0 _.unit(3);
  background: var(--color-layer-1);
  border: 1px solid var(--color-border);
  border-radius: 8px;
  outline: 3px solid transparent;
  transition-property: outline, border;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  font: var(--font-body-2);
  cursor: pointer;
  position: relative;

  &.multiple {
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: _.unit(2);
    padding: _.unit(1.5) _.unit(3);
    cursor: text;

    .tag {
      cursor: auto;
      display: flex;
      align-items: center;
      gap: _.unit(1);
      position: relative;

      &.focused::after {
        content: '';
        position: absolute;
        inset: 0;
        background: var(--color-overlay-default-focused);
      }

      &.info {
        background: var(--color-error-container);
      }
    }

    .close {
      width: 16px;
      height: 16px;
    }

    .delete {
      width: 20px;
      height: 20px;
      margin-right: _.unit(-0.5);
    }

    input {
      color: var(--color-text);
      font: var(--font-body-2);
      background: transparent;
      flex-grow: 1;
      padding: _.unit(0.5);

      &::placeholder {
        color: var(--color-placeholder);
      }
    }
  }

  &:focus-within {
    border-color: var(--color-primary);
    outline-color: var(--color-focused-variant);
  }

  &.error {
    border-color: var(--color-error);

    &:focus-within {
      outline-color: var(--color-danger-focused);
    }
  }
}

.errorMessage {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: _.unit(1);
}
