const application = {
    invalid_type: 'Sadece makine ile makine uygulamaları rollerle ilişkilendirilebilir.',
    role_exists: 'Bu uygulamaya zaten {{roleId}} kimlikli bir rol eklenmiş.',
    invalid_role_type: 'Kullanıcı tipi rolü makine ile makine uygulamasına atayamaz.',
    invalid_third_party_application_type: 'Sadece geleneksel web uygulamaları üçüncü taraf uygulaması olarak işaretlenebilir.',
    third_party_application_only: 'Bu özellik sadece üçüncü taraf uygulamalar için geçerlidir.',
    user_consent_scopes_not_found: 'Geçersiz kullanıcı onay kapsamları.',
    /** UNTRANSLATED */
    consent_management_api_scopes_not_allowed: 'Management API scopes are not allowed.',
    protected_app_metadata_is_required: 'Protected app metadata is required.',
    /** UNTRANSLATED */
    protected_app_not_configured: 'Protected app provider is not configured. This feature is not available for open source version.',
    /** UNTRANSLATED */
    cloudflare_unknown_error: 'Got unknown error when requesting Cloudflare API',
    /** UNTRANSLATED */
    protected_application_only: 'The feature is only available for protected applications.',
    /** UNTRANSLATED */
    protected_application_misconfigured: 'Protected application is misconfigured.',
    /** UNTRANSLATED */
    protected_application_subdomain_exists: 'The subdomain of Protected application is already in use.',
    /** UNTRANSLATED */
    invalid_subdomain: 'Invalid subdomain.',
    /** UNTRANSLATED */
    custom_domain_not_found: 'Custom domain not found.',
    /** UNTRANSLATED */
    should_delete_custom_domains_first: 'Should delete custom domains first.',
};
export default Object.freeze(application);
