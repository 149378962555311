@use '@/scss/underscore' as _;

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .cardTitle {
    flex-shrink: 0;
    margin-bottom: _.unit(4);
  }
}
