@use '@/scss/underscore' as _;

.userInfo {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: default;
}

.nameWrapper {
  display: flex;
  flex-direction: column;
  margin-left: _.unit(3);

  .name {
    font: var(--font-label-2);
    color: var(--color-text);
  }

  .email {
    font: var(--font-body-3);
    color: var(--color-text-secondary);
  }
}
