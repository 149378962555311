@use '@/scss/underscore' as _;

.helpButton {
  display: flex;
  align-items: center;
  padding: _.unit(1);
  border-radius: 6px;
  border: none;
  background-color: none;
  transition: background-color 0.2s ease-in-out;
  user-select: none;
  outline: none;
  cursor: pointer;
  gap: _.unit(1);
  margin-left: _.unit(-1);

  &:hover {
    background-color: var(--color-hover-variant);
  }

  &.active {
    background-color: var(--color-focused-variant);
  }

  .icon {
    width: 20px;
    height: 20px;

    > path {
      fill: var(--color-neutral-variant-50);
    }
  }

  span {
    font: var(--font-label-2);
    color: var(--color-neutral-variant-30);
  }
}
