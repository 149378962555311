const organization_details = {
    page_title: 'Szczegóły organizacji',
    delete_confirmation: 'Po usunięciu wszyscy członkowie stracą swoje członkostwo i role w tej organizacji. Tego działania nie można cofnąć.',
    organization_id: 'ID organizacji',
    settings_description: 'Organizacje reprezentują zespoły, klientów biznesowych i partnerów, którzy mogą mieć dostęp do Twoich aplikacji.',
    name_placeholder: 'Nazwa organizacji, nie musi być unikalna.',
    description_placeholder: 'Opis organizacji.',
    member: 'Członek',
    member_other: 'Członkowie',
    add_members_to_organization: 'Dodaj członków do organizacji {{name}}',
    add_members_to_organization_description: 'Znajdź odpowiednich użytkowników, wyszukując nazwę, adres e-mail, numer telefonu lub identyfikator użytkownika. Istniejący członkowie nie są wyświetlani w wynikach wyszukiwania.',
    add_with_organization_role: 'Dodaj z rolą(ami) organizacji',
    user: 'Użytkownik',
    authorize_to_roles: 'Autoryzuj {{name}} dostęp do następujących ról:',
    edit_organization_roles: 'Edytuj role organizacji',
    edit_organization_roles_of_user: 'Edytuj role organizacji użytkownika {{name}}',
    remove_user_from_organization: 'Usuń użytkownika z organizacji',
    remove_user_from_organization_description: 'Po usunięciu użytkownik straci swoje członkostwo i role w tej organizacji. Tego działania nie można cofnąć.',
    search_user_placeholder: 'Wyszukaj według nazwy, adresu e-mail, numeru telefonu lub identyfikatora użytkownika',
    at_least_one_user: 'Wymagany jest co najmniej jeden użytkownik.',
};
export default Object.freeze(organization_details);
