const demo_app = {
    title: 'Canlı önizlemede başarıyla oturum açtınız!',
    subtitle: 'Sisteme giriş bilgileriniz:',
    username: 'Kullanıcı Adı: ',
    user_id: 'Kullanıcı Kimliği: ',
    sign_out: 'Canlı Önizlemeyi Kapat',
    continue_explore: 'Veya keşfetmeye devam et',
    customize_sign_in_experience: 'Oturum Açma Deneyimini Özelleştir',
    enable_passwordless: 'Şifresiz Etkinleştir',
    add_social_connector: 'Sosyal Bağlantı Ekle',
};
export default Object.freeze(demo_app);
