@use '@/scss/underscore' as _;

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: _.unit(6);
  border-radius: 12px;
  background: var(--color-layer-light);
  gap: _.unit(3);

  .title {
    font: var(--font-title-3);
  }

  .description {
    font: var(--font-body-2);
  }
}
