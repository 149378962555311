@use '@/scss/underscore' as _;

.container {
  padding: _.unit(6);
  border-radius: _.unit(2);
  background-color: var(--color-layer-light);

  > div {
    display: flex;
    flex-direction: column;
    gap: _.unit(1);

    .title {
      font: var(--font-title-3);
    }

    .content {
      max-width: 100%;
      font: var(--font-body-2);
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }

  > :not(:last-child) {
    margin-bottom: _.unit(6);
  }
}

.certificatePreview {
  display: flex;
  flex-direction: row;
  align-items: center;
  font: var(--font-body-2);

  .indicator {
    width: 10px;
    height: 10px;
    margin-right: _.unit(2);
    border-radius: 50%;
    background: var(--color-on-success-container);
  }

  .errorStatus {
    background: var(--color-on-error-container);
  }

  .copyToClipboard {
    margin-left: _.unit(1);
  }
}
