const tab_sections = {
    overview: 'Visión general',
    resources: 'Recursos',
    users: 'Usuarios',
    access_control: 'Control de acceso',
    help_and_support: 'Ayuda y soporte',
    tenant: 'Inquilino',
    automation: 'Automatización',
};
export default Object.freeze(tab_sections);
