const permissions = {
    search_placeholder: 'APIまたは権限名で検索',
    search_placeholder_without_api: '権限名で検索',
    name_column: '権限',
    description_column: '説明',
    api_column: 'API',
    placeholder_title: '権限',
    placeholder_description: '権限はリソース（APIリソースと呼んでいます）にアクセスするための承認を指します。',
};
export default Object.freeze(permissions);
