@use '@/scss/underscore' as _;

.launcher {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: _.unit(3) _.unit(4);
  background: var(--color-success-container);
  border: 1px solid var(--color-success-60);
  border-radius: 8px;
  font: var(--font-body-2);
  gap: _.unit(4);

  span {
    white-space: pre-wrap;
  }

  .button {
    background: var(--color-success-60);
    color: var(--color-static-white);
    border: none;
    flex-shrink: 0;
    gap: _.unit(1.5);

    svg {
      color: rgba(#fff, 0.6);
    }

    &:active {
      background: var(--color-success-50);
    }

    &:not(:disabled):not(:active):hover {
      background: var(--color-success-70);
    }
  }
}

.customDomain {
  margin-bottom: _.unit(3);
}

.label {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-bottom: _.unit(1);
}

.hostInUse {
  display: flex;
  align-items: center;
  gap: _.unit(6);
  border-radius: 8px;
  border: 1px solid var(--color-divider);
  background: var(--color-layer-1);
  padding: _.unit(5) _.unit(6);

  .host {
    font: var(--font-label-1);
    white-space: nowrap;
  }

  .status {
    display: flex;
    align-items: center;
    gap: _.unit(2);
    font: var(--font-body-2);
    white-space: nowrap;

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;

      &.success {
        background: var(--color-on-success-container);
      }

      &.connecting {
        background: var(--color-on-alert-container);
      }
    }
  }
}

.inlineCode {
  font: var(--font-body-2);
  font-family: 'Roboto Mono', monospace;
  background-color: var(--color-bg-layer-2);
  color: var(--color-text);
  border-radius: 6px;
  padding: _.unit(0.5) _.unit(1.5);
}

.routes {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: _.unit(3) _.unit(6);
}

.tip {
  ol {
    margin: 0;
    padding-inline-start: _.unit(5);
  }
}

.loadingSkeleton {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: _.unit(2);
  padding: _.unit(6);
  border-radius: 12px;
  border: 1px solid var(--color-divider);

  .bone {
    @include _.shimmering-animation;
    border-radius: 8px;
  }

  .title {
    width: 100%;
    height: 20px;
  }

  .description {
    width: 100%;
    height: 16px;
  }
}
