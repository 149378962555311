@use '@/scss/underscore' as _;
@use '@/scss/dimensions' as dim;

.container {
  display: flex;
  flex-direction: column;
  background-color: var(--color-base);
  width: 100vw;
  height: 100vh;
  overflow-x: auto;

  > * {
    min-width: dim.$guide-content-min-width;
  }

  .content {
    flex: 1;
    overflow: hidden;
  }
}
