@use '@/scss/underscore' as _;

.wrapper {
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
    margin-right: _.unit(2);
  }

  span {
    @include _.text-ellipsis;
    max-width: 292px;
  }
}
