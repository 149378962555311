@use '@/scss/underscore' as _;

.container {
  .title {
    font: var(--font-title-1);
  }

  .sectionTitle {
    font: var(--font-title-2);
  }

  .description {
    font: var(--font-body-2);
  }

  .panel {
    flex: 1;
  }

  .compact {
    padding: _.unit(6);
  }
}
