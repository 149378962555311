@use '@/scss/underscore' as _;

div.toast {
  display: flex;
  align-items: center;
  padding: _.unit(3) _.unit(4);
  font: var(--font-body-2);
  color: var(--color-text);
  box-shadow: var(--shadow-1);
  border-radius: _.unit(2);
  max-width: 412px;

  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
  }

  .message {
    flex: 1;
    margin: 0 _.unit(3);
    overflow: hidden;
    overflow-wrap: break-word;
  }

  &.success {
    border: 1px solid var(--color-success-70);
    background-color: var(--color-success-99);
  }

  &.error {
    border: 1px solid var(--color-error);
    background-color: var(--color-danger-toast-background);
    white-space: pre-line;
  }
}
