@use '@/scss/underscore' as _;

.quotaListItem {
  font: var(--font-body-2);
  // Add a margin to the left for the list item marker
  margin-left: _.unit(4);

  .content {
    display: flex;
    align-items: center;
    gap: _.unit(2);
  }

  .icon {
    width: 16px;
    height: 16px;

    &.notCapable {
      color: var(--color-error);
    }
  }

  .lineThrough {
    text-decoration: line-through;
  }

  &.withIcon {
    list-style-type: none;
    // Unset a margin to the left for the list item marker
    margin-left: unset;
  }
}
