const application_details = {
    page_title: 'Detalles de la aplicación',
    back_to_applications: 'Volver a Aplicaciones',
    check_guide: 'Revisar Guía',
    settings: 'Configuraciones',
    /** UNTRANSLATED */
    settings_description: 'An "Application" is a registered software or service that can access user info or act for a user. Applications help recognize who’s asking for what from Logto and handle the sign-in and permission. Fill in the required fields for authentication.',
    /** UNTRANSLATED */
    integration: 'Integration',
    /** UNTRANSLATED */
    integration_description: "Deploy with Logto secure workers, powered by Cloudflare's edge network for top-tier performance and 0ms cold starts worldwide.",
    /** UNTRANSLATED */
    service_configuration: 'Service configuration',
    /** UNTRANSLATED */
    service_configuration_description: 'Complete the necessary configurations in your service.',
    /** UNTRANSLATED */
    session: 'Session',
    /** UNTRANSLATED */
    endpoints_and_credentials: 'Endpoints & Credentials',
    /** UNTRANSLATED */
    endpoints_and_credentials_description: 'Use the following endpoints and credentials to set up the OIDC connection in your application.',
    /** UNTRANSLATED */
    refresh_token_settings: 'Refresh token',
    /** UNTRANSLATED */
    refresh_token_settings_description: 'Manage the refresh token rules for this application.',
    application_roles: 'Roles de Aplicación',
    machine_logs: 'Registros de Máquina',
    application_name: 'Nombre de Aplicación',
    application_name_placeholder: 'Mi App',
    description: 'Descripción',
    description_placeholder: 'Ingresa la descripción de tu aplicación',
    config_endpoint: 'Endpoint de configuración del proveedor OpenID',
    authorization_endpoint: 'Endpoint de Autorización',
    authorization_endpoint_tip: 'El endpoint para la autenticación y autorización. Se utiliza para OpenID Connect <a>Autenticación</a>.',
    /** UNTRANSLATED */
    show_endpoint_details: 'Show endpoint details',
    /** UNTRANSLATED */
    hide_endpoint_details: 'Hide endpoint details',
    logto_endpoint: 'Endpoint de Logto',
    application_id: 'ID de Aplicación',
    application_id_tip: 'El identificador de aplicación único normalmente generado por Logto. También se conoce como “<a>client_id</a>” en OpenID Connect.',
    application_secret: 'Aplicación Secreta',
    redirect_uri: 'URI de Redireccionamiento',
    redirect_uris: 'URIs de Redireccionamiento',
    redirect_uri_placeholder: 'https://tu.pagina.com/app',
    redirect_uri_placeholder_native: 'io.logto://callback',
    redirect_uri_tip: 'El URI hacia donde se redirecciona después de que un usuario inicie sesión (correctamente o no). Consulta OpenID Connect <a>AuthRequest</a> para más información.',
    post_sign_out_redirect_uri: 'Post Sign-out URI de Redireccionamiento',
    post_sign_out_redirect_uris: 'Post Sign-out URIs de Redireccionamiento',
    post_sign_out_redirect_uri_placeholder: 'https://tu.pagina.com/home',
    post_sign_out_redirect_uri_tip: 'El URI hacia donde se redirecciona después de que un usuario cierre sesión (opcional). Puede que no tenga efecto para algunos tipos de aplicaciones.',
    cors_allowed_origins: 'Orígenes permitidos CORS',
    cors_allowed_origins_placeholder: 'https://tu.pagina.com',
    cors_allowed_origins_tip: 'Por defecto, se permitirán todos los orígenes de los URIs de Redireccionamiento. Normalmente no es necesario hacer nada en este campo. Consulta la <a>documentación de MDN</a> para obtener información detallada.',
    token_endpoint: 'Endpoint del Token',
    user_info_endpoint: 'Endpoint del Usuario',
    enable_admin_access: 'Habilitar acceso de administrador',
    enable_admin_access_label: 'Habilita o deshabilita el acceso a la API de Gestión. Una vez habilitado, puedes utilizar tokens de acceso para llamar a la API de Gestión en nombre de esta aplicación.',
    always_issue_refresh_token: 'Siempre emitir Token de Refresco',
    always_issue_refresh_token_label: 'Al habilitar esta configuración, Logto siempre emitirá Tokens de Refresco, independientemente de si se presenta o no “prompt=consent” en la solicitud de autenticación. Sin embargo, esta práctica no está recomendada a menos que sea necesario, ya que no es compatible con OpenID Connect y puede causar problemas potenciales.',
    refresh_token_ttl: 'Tiempo de vida útil del Token de refresco (TTL) en días',
    refresh_token_ttl_tip: 'La duración durante la cual un token de refresco puede ser utilizado para solicitar nuevos tokens de acceso antes de que expire y se vuelva inválido. Las solicitudes de tokens extenderán el TTL del token de refresco a este valor.',
    rotate_refresh_token: 'Rotar el token de refresco',
    rotate_refresh_token_label: 'Cuando está habilitado, Logto emitirá un nuevo token de refresco para las solicitudes de token cuando ha pasado el 70 % del tiempo de vida útil (TTL) original o se cumplen ciertas condiciones. <a>Más información</a>',
    delete_description: 'Esta acción no se puede deshacer. Eliminará permanentemente la aplicación. Ingresa el nombre de la aplicación <span>{{name}}</span> para confirmar.',
    enter_your_application_name: 'Ingresa el nombre de tu aplicación',
    application_deleted: 'Se ha eliminado exitosamente la aplicación {{name}}',
    redirect_uri_required: 'Debes ingresar al menos un URI de Redireccionamiento',
    /** UNTRANSLATED */
    app_domain_description_1: 'Feel free to use your domain with {{domain}} powered by Logto, which is permanently valid.',
    /** UNTRANSLATED */
    app_domain_description_2: 'Feel free to utilize your domain <domain>{{domain}}</domain> which is permanently valid.',
    /** UNTRANSLATED */
    custom_rules: 'Custom authentication rules',
    /** UNTRANSLATED */
    custom_rules_placeholder: '^/(admin|privacy)/.+$',
    /** UNTRANSLATED */
    custom_rules_description: 'Set rules with regular expressions for authentication-required routes. Default: full-site protection if left blank.',
    /** UNTRANSLATED */
    authentication_routes: 'Authentication routes',
    /** UNTRANSLATED */
    custom_rules_tip: "Here are two case scenarios:<ol><li>To only protect routes '/admin' and '/privacy' with authentication: ^/(admin|privacy)/.*</li><li>To exclude JPG images from authentication: ^(?!.*\\.jpg$).*$</li></ol>",
    /** UNTRANSLATED */
    authentication_routes_description: 'Redirect your authentication button using the specified routes. Note: These routes are irreplaceable.',
    /** UNTRANSLATED */
    protect_origin_server: 'Protect your origin server',
    /** UNTRANSLATED */
    protect_origin_server_description: 'Ensure to protect your origin server from direct access. Refer to the guide for more <a>detailed instructions</a>.',
    /** UNTRANSLATED */
    session_duration: 'Session duration (days)',
    /** UNTRANSLATED */
    try_it: 'Try it',
    branding: {
        /** UNTRANSLATED */
        name: 'Branding',
        /** UNTRANSLATED */
        description: "Customize your application's display name and logo on the consent screen.",
        /** UNTRANSLATED */
        more_info: 'More info',
        /** UNTRANSLATED */
        more_info_description: 'Offer users more details about your application on the consent screen.',
        /** UNTRANSLATED */
        display_name: 'Display name',
        /** UNTRANSLATED */
        display_logo: 'Display logo',
        /** UNTRANSLATED */
        display_logo_dark: 'Display logo (dark)',
        /** UNTRANSLATED */
        terms_of_use_url: 'Application terms of use URL',
        /** UNTRANSLATED */
        privacy_policy_url: 'Application privacy policy URL',
    },
    permissions: {
        /** UNTRANSLATED */
        name: 'Permissions',
        /** UNTRANSLATED */
        description: 'Select the permissions that the third-party application requires for user authorization to access specific data types.',
        /** UNTRANSLATED */
        user_permissions: 'Personal user data',
        /** UNTRANSLATED */
        organization_permissions: 'Organization access',
        /** UNTRANSLATED */
        table_name: 'Grant permissions',
        /** UNTRANSLATED */
        field_name: 'Permission',
        /** UNTRANSLATED */
        field_description: 'Displayed in the consent screen',
        /** UNTRANSLATED */
        delete_text: 'Remove permission',
        /** UNTRANSLATED */
        permission_delete_confirm: 'This action will withdraw the permissions granted to the third-party app, preventing it from requesting user authorization for specific data types. Are you sure you want to continue?',
        /** UNTRANSLATED */
        permissions_assignment_description: 'Select the permissions the third-party application requests for user authorization to access specific data types.',
        /** UNTRANSLATED */
        user_profile: 'User data',
        /** UNTRANSLATED */
        api_resource: 'API resource',
        /** UNTRANSLATED */
        organization: 'Organization',
        /** UNTRANSLATED */
        user_permissions_assignment_form_title: 'Add the user profile permissions',
        /** UNTRANSLATED */
        organization_permissions_assignment_form_title: 'Add the organization permissions',
        /** UNTRANSLATED */
        api_resource_permissions_assignment_form_title: 'Add the API resource permissions',
        /** UNTRANSLATED */
        user_data_permission_description_tips: 'You can modify the description of the personal user data permissions via "Sign-in Experience > Content > Manage Language"',
        /** UNTRANSLATED */
        permission_description_tips: 'When Logto is used as an Identity Provider (IdP) for authentication in third-party apps, and users are asked for authorization, this description appears on the consent screen.',
    },
    roles: {
        name_column: 'Rol',
        description_column: 'Descripción',
        assign_button: 'Asignar roles',
        delete_description: 'Esta acción eliminará este rol de esta aplicación de máquina a máquina. El rol seguirá existiendo, pero ya no estará asociado con esta aplicación de máquina a máquina.',
        deleted: 'Se ha eliminado correctamente {{name}} de este usuario.',
        assign_title: 'Asignar roles a {{name}}',
        assign_subtitle: 'Autorizar {{name}} uno o más roles',
        assign_role_field: 'Asignar roles',
        role_search_placeholder: 'Buscar por nombre de rol',
        added_text: '{{value, number}} añadido',
        assigned_app_count: '{{value, number}} aplicaciones',
        confirm_assign: 'Asignar roles',
        role_assigned: 'Rol(es) asignado(s) correctamente',
        search: 'Buscar por nombre de rol, descripción o ID',
        empty: 'No hay roles disponibles',
    },
};
export default Object.freeze(application_details);
