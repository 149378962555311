const organization_details = {
    page_title: 'Organisationsdetails',
    delete_confirmation: 'Wenn gelöscht, verlieren alle Mitglieder ihre Mitgliedschaft und Rollen in dieser Organisation. Diese Aktion kann nicht rückgängig gemacht werden.',
    organization_id: 'Organisations-ID',
    settings_description: 'Organisationen repräsentieren die Teams, Geschäftskunden und Partnerunternehmen, die auf Ihre Anwendungen zugreifen können.',
    name_placeholder: 'Der Name der Organisation, muss nicht eindeutig sein.',
    description_placeholder: 'Eine Beschreibung der Organisation.',
    member: 'Mitglied',
    member_other: 'Mitglieder',
    add_members_to_organization: 'Mitglieder zur Organisation {{name}} hinzufügen',
    add_members_to_organization_description: 'Suchen Sie nach geeigneten Benutzern, indem Sie nach Name, E-Mail, Telefon oder Benutzer-ID suchen. In den Suchergebnissen werden keine bereits vorhandenen Mitglieder angezeigt.',
    add_with_organization_role: 'Mit Organisation Rollen hinzufügen',
    user: 'Benutzer',
    authorize_to_roles: 'Berechtige {{name}} auf die folgenden Rollen zuzugreifen:',
    edit_organization_roles: 'Organisationsrollen bearbeiten',
    edit_organization_roles_of_user: 'Organisationsrollen von {{name}} bearbeiten',
    remove_user_from_organization: 'Benutzer von der Organisation entfernen',
    remove_user_from_organization_description: 'Wenn entfernt, verliert der Benutzer seine Mitgliedschaft und Rollen in dieser Organisation. Diese Aktion kann nicht rückgängig gemacht werden.',
    search_user_placeholder: 'Nach Name, E-Mail, Telefon oder Benutzer-ID suchen',
    at_least_one_user: 'Mindestens ein Benutzer ist erforderlich.',
};
export default Object.freeze(organization_details);
