@use '@/scss/underscore' as _;

.container {
  border-radius: 12px;
  border: 1px solid var(--color-divider);
  overflow: hidden;

  .separator {
    border-bottom: 1px solid var(--color-divider);
  }

  .form {
    background: var(--color-layer-light);
    padding: _.unit(6) _.unit(8) _.unit(8) _.unit(11);
  }

  .protectedApps {
    display: flex;
    flex-direction: column;
    gap: _.unit(3);
    background: var(--color-success-container);
    padding: _.unit(5) _.unit(9);

    .label {
      display: flex;
      align-items: center;
      font: var(--font-label-2);
      white-space: pre-wrap;
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      gap: _.unit(3) _.unit(8);
    }

    .app {
      display: flex;
      align-items: center;
      padding-left: _.unit(5);
      gap: _.unit(3);
      font: var(--font-body-2);

      .status {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: var(--color-on-success-container);
        margin-right: _.unit(-1);
      }

      .hostName {
        margin-right: _.unit(-3);
        color: var(--color-text);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.loadingSkeleton {
  display: flex;
  align-items: center;
  gap: _.unit(6);
  padding: _.unit(6) _.unit(8);
  border-radius: 12px;
  border: 1px solid var(--color-divider);

  .bone {
    @include _.shimmering-animation;
    border-radius: 8px;
  }

  .columnWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: _.unit(2);
  }

  .icon {
    width: 48px;
    height: 48px;
  }

  .title {
    width: 100%;
    height: 20px;
  }

  .description {
    width: 100%;
    height: 16px;
  }
}
