@use '@/scss/underscore' as _;

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;

  .image {
    width: 200px;
    height: 200px;
  }

  .title {
    font: var(--font-label-2);
  }

  .text {
    margin-top: _.unit(1);
    font: var(--font-body-2);
    color: var(--color-text-secondary);
    text-align: center;
  }

  .button {
    margin-top: _.unit(6);
  }
}
