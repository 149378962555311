const get_started = {
    page_title: 'Başlangıca Başlayın',
    title: 'Başarınızı Desteklemek İçin Keşfedilecek Bir Şey',
    subtitle: "Logto'nun değerini hızla elde etmek için yapabileceğiniz birkaç şey",
    develop: {
        title: 'Geliştir: Uygulamanızı entegre etmek için 5 dakika ayırın',
        /** UNTRANSLATED */
        title_cloud: 'Develop: Securely integrate your app in minutes',
        /** UNTRANSLATED */
        subtitle_cloud: 'Or take 5 minutes to integrate your app with our pre-built SDKs and tutorials.',
    },
    customize: {
        title: 'Özelleştir: Harika bir oturum açma deneyimi sunun',
        preview: {
            title: 'Yeni özelleştirdiğiniz oturum açma deneyiminin canlı önizlemesini kontrol edin',
            subtitle: 'Nasıl çalıştığını görmek için şimdi Logto oturum açma deneyimini deneyin',
        },
        connector: {
            title: 'Daha fazla bağdaştırıcı ekleyerek daha fazla sosyal oturum açma yöntemini destekleyin',
            subtitle: 'Şifresiz oturum açmayı deneyin ve müşterileriniz için güvenli ve sürtünmesiz bir deneyim sağlayın',
        },
        continue_customizing: 'Özelleştirmeye devam et',
        try_now: 'Şimdi deneyin',
        add_more: 'Daha fazla ekle',
    },
    secure: {
        title: 'Güvenli: Kaynaklarınızı koruyun',
    },
    manage: {
        title: 'Yönet: Ürününüz ve kullanıcılarınız için erişim denetimi tanımlayın',
        rbac: {
            title: 'Diverse kullanım durumları için ölçeklenebilir rol yetkilendirmesi ile kaynaklarınızı koruyun',
            subtitle: 'Kaynağınızı kontrol edin',
        },
        create_roles: 'Roller oluştur',
    },
    view_all: 'Hepsini görüntüle →',
};
export default Object.freeze(get_started);
