@use '@/scss/underscore' as _;

.permissionPage {
  height: 100%;
}

.deleteConfirm {
  > :not(:first-child) {
    margin-top: _.unit(6);
  }

  .description {
    font: var(--font-body-2);
  }

  .highlight {
    color: var(--color-primary-50);
  }
}
