@use '@/scss/underscore' as _;

.fileInput {
  display: none;
}

.preview {
  display: flex;
  max-width: 400px;
  align-items: center;
  flex-direction: row;
  padding: _.unit(5) _.unit(5) _.unit(5) _.unit(4);
  background: var(--color-layer-1);
  color: var(--color-text);
  border: 1px solid var(--color-border);
  border-radius: _.unit(3);

  .fileInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: _.unit(0.5);
    flex: 1;
    margin-left: _.unit(2);

    .fileName {
      font: var(--font-label-2);
    }

    .fileSize {
      font: var(--font-label-3);
      color: var(--color-text-secondary);
    }
  }

  .delete {
    display: flex;
    width: 28px;
    height: 28px;
    border-radius: _.unit(1.5);
    border: none;
    padding: _.unit(1);
    background-color: transparent;

    .icon {
      color: var(--color-text-secondary);
    }

    &:hover {
      background: var(--color-hover);
      border-radius: 6px;
    }
  }
}

.error {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: _.unit(1);
}
