const tab_sections = {
    overview: "Vue d'ensemble",
    resources: 'Ressources',
    users: 'Utilisateurs',
    access_control: "Contrôle d'accès",
    help_and_support: 'Aide et support',
    tenant: 'Locataire',
    automation: 'Automatisation',
};
export default Object.freeze(tab_sections);
