@use '@/scss/underscore' as _;

.check {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg + span {
    margin-left: _.unit(1);
  }
}
