@use '@/scss/underscore' as _;

.description {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
  margin-top: _.unit(0.5);
}

.certificatePreview {
  margin-top: _.unit(1);
}
