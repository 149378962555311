.search {
  width: 100%;
}

.icon {
  color: var(--color-text-secondary);
}

.emptyImage {
  width: 128px;
  height: 128px;
}
