@use '@/scss/underscore' as _;

.title {
  @include _.section-head-1;
  color: var(--color-neutral-variant-60);
}

.manageLanguageButton {
  margin-top: _.unit(1);
}

.defaultLanguageDescription {
  padding-top: _.unit(2);
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}
