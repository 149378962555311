@use '@/scss/underscore' as _;

.container {
  border-radius: 6px;
  border: 1px solid var(--color-border);
  outline: 3px solid transparent;
  transition-property: outline, border;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  padding: _.unit(1.5) _.unit(3);
  font: var(--font-body-2);
  display: flex;
  align-items: center;

  &:focus,
  &.highlight {
    border-color: var(--color-primary);
    outline-color: var(--color-focused-variant);
  }

  .brick {
    flex-shrink: 0;
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 1px solid var(--color-divider);
    border-radius: 4px;
    margin-right: _.unit(2);
  }
}
