@use '@/scss/underscore' as _;

.container {
  display: flex;

  > * {
    flex: 1;

    // remove the left side of the border-radius on the second column when dark mode logo uploader is active
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.darkMode {
      background-color: #000;
    }
  }

  .multiColumn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: _.unit(1);
}

.error {
  color: var(--color-error);
}
