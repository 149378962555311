@use '@/scss/underscore' as _;

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;

  input {
    opacity: 0%;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-neutral-90);
    transition: 0.4s;
    border-radius: 12px;

    &::before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      background-color: #fff;
      transition: 0.4s;
      border-radius: 50%;
      box-shadow: 0 3px 7px rgba(0, 0, 0, 12%);
    }
  }

  input:checked + .slider {
    background-color: var(--color-success-70);
  }

  input:checked + .slider::before {
    transform: translateX(16px);
  }

  input:disabled + .slider {
    cursor: not-allowed;
  }

  input:disabled + .slider::before {
    background-color: var(--color-specific-toggle-thumb-disabled);
    box-shadow: unset;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  border: 1px solid var(--color-neutral-90);
  border-radius: _.unit(2);
  padding: _.unit(4);

  .label {
    flex: 1;
    margin-right: _.unit(2);
    font: var(--font-body-2);
  }

  &.error {
    border-color: var(--color-error);

    &:focus-within {
      outline-color: var(--color-danger-focused);
    }
  }
}
